import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Layout from 'components/layout';
import PhoneInputPage from 'components/users/binding/input';
import { phoneValidationSuccessPromise } from 'src/lib/liff';
import Loading from 'src/components/1TalkDesign/Loading';
import { getUserInfo, validatePasscode } from '../../../services/user.service';
import { getSearchParamsStrings } from 'src/utils';
import { t } from 'src/i18n/config';

const Empty = styled.div`
  margin-top: 46px;
  width: 100%;
  text-align: center;
`;

const PhoneInput = ({ lineProfilePhone }) => {
  const searchParams = getSearchParamsStrings();
  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  const [user, setUser] = useState();

  useEffect(() => {
    getUserInfo()
      .then(async (res) => {
        setUser(res);
        if (lineProfilePhone && res && !res.Phone) {
          await handleValidate(res);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []) // eslint-disable-line

  const handleValidate = async (user) => {
    await validatePasscode({ phone: lineProfilePhone, lineVerify: true });
    const trimmedPhone = lineProfilePhone.replace(/\+886/g, '0');
    await phoneValidationSuccessPromise(trimmedPhone);
    if (user.patients.length === 0) {
      await navigate(`/users/create${searchParams}`, {
        replace: true,
        state: { user: { phone: lineProfilePhone } },
      });
    } else {
      await navigate('/users/create/success', { replace: true });
    }
    setValidated(true);
  };

  // loading 或 已驗證都不會顯示手機驗證頁面，已驗證會跳轉到成功頁面or家屬建檔頁
  return loading || validated ? (
    <Empty>
      <Loading color="rgb(0, 109, 177)"></Loading>
    </Empty>
  ) : (
    <Layout>
      <Helmet>
        <title>{t('phoneNumberBinding')}</title>
      </Helmet>
      <PhoneInputPage user={user} />
    </Layout>
  );
};
export default PhoneInput;
