import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(() => ({
  formControl: {},
  selectEmpty: {},
  select: {
    height: 40,
  },
}));

const NativeSelects = ({
  className,
  defaultValue,
  options,
  onChange,
  disabled,
}) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    value: defaultValue,
  });

  useEffect(() => {
    setState({
      value: defaultValue,
    });
  }, [defaultValue]);

  const handleChange = (event) => {
    setState({
      ...state,
      value: event.target.value,
    });
    onChange(event.target.value);
  };

  return (
    <FormControl
      variant="outlined"
      className={`${classes.formControl} ${className}`}
    >
      <Select
        native
        className={classes.select}
        onChange={handleChange}
        value={state.value}
        disabled={disabled}
      >
        {options.map((o) => {
          return (
            <option key={o.id} name={o.value} value={o.value}>
              {o.title}
            </option>
          );
        })}
      </Select>
    </FormControl>
  );
};

NativeSelects.propTypes = {
  defaultValue: PropTypes.string,
  title: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

export default NativeSelects;
